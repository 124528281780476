import LayoutA6 from "../../components/layout-a6"
import React from "react"
import EnrollInfo from '../../components/enroll-info'

export default class A6EnrollInfo extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    const src='/demos/themeA6/profile/enrollInfoDetail'
    return(
      <LayoutA6 location={this.props.location} >
        <EnrollInfo src={src} color={color}/>
      </LayoutA6>
    )
  }
}